@import '../../util/Constant/sass-variable-list.scss';

.map-svg {
  &__svg {
    background-color: $blue--lightest;
    border: 1px solid $white;
    box-shadow: 0 0 50px 0 inset $blue;
    height: 71vh;
    width: 100%;
    @media (min-width: $media-switch--medium) {
      height: 77vh;
    }
  }

  &__data {
    height: 100px;
    width: 100px;
  }

  &__credit {
    font-size: $font-size--large;
    line-height: $line-height--large;
    padding: $padding--medium 0 $padding--very-large;
    text-align: center;
    width: 100%;
  }
}
