@import '../../util/Constant/sass-variable-list.scss';

.gantt-chart {
  position: relative;
  top: 80px;
  width: 100%;

  &__zoom-button-list {
    position: relative;
  }

  &__zoom-button-list-wrapper {
    box-sizing: border-box;
    height: 40px;
    justify-content: center;
    padding: 0 $spacing--narrow;
    position: absolute;
    top: -40px;
    width: 100%;
  }

  &__zoom-button {
    &.is-selected {
      background-color: rgba($green--dark, 0.15);
      box-shadow: 0 0 2px 0 inset $green;
      color: $black;
    }
  }
}