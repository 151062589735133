.time-line {
  width: 100%;
  &__filter-list-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &__filter-list {
    position: relative;
  }

  &__heat-map {
    width: 100%;
  }

  &__month {
    display: inline;
  }
}
