body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.sb-show-main.sb-show-main.sb-show-main {
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

dd, dl, dt, li, ol, ul,
caption, figure,
h1, h2, h3, h4, h5, h6, p {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}


/*
 * Utils
 */
.outline-force:focus {
  outline-style: auto;
}
.hide.hide {
  display: none;
}


/*
  NOTE: These are duplciated here so StoryBook can find the basic;
  styles, see issue for this: #90;
*/
:root {
  /* spacing  */
  --spacing--narrowest: 2px;
  --spacing--narrow: 8px;
  --spacing--wide: 20px;

  /* padding */
  --padding--tiniest: 1px;
  --padding--small-tiny: 3px;
  --padding--small: 6px;
  --padding--medium: 8px;
  --padding--medium-large: 10px;
  --padding--large: 12px;
  --padding--very-large: 20px;


  /* colours  */
  --blue--dark: #129;
  --blue--dull-dark: #23d;
  --blue: #46d;
  --blue--light: #60a0fc;
  --blue--lightest: #ccddff;

  --red--black: #700;
  --red--dark: #b22;
  --red--medium: #f77;
  --red--dulled-light: #f99;
  --red--light: #fbb;
  --red--very-light: #fdd;
  --red--lightest: #fee;

  --green--dark: #096;
  --green: #4e8;
  --green--lightest: #d0fff3;

  --orange--dark: #870;
  --orange: #cb4;
  --orange--light: #ed6;

  /* greyscale  */
  --black: #000;
  --grey--black: #181818;
  --grey--dark: #333;
  --grey--dull-dark: #555;
  --grey--medium: #888;
  --grey--light: #aaa;
  --grey--very-light: #cacaca;
  --grey--lightest: #e7e7e7;
  --grey--white: #f6f6f6;
  --white: #fff;


  /* font size */
  --font-size--small: 10px;
  --font-size--medium-small: 12px;
  --font-size--medium: 14px;
  --font-size--medium-large: 16px;
  --font-size--large: 18px;
  --line-height--small: 1.2em;
  --line-height--large: 1.5em;

  /* font weight */
  --font-weight--light: 200;
  --font-weight--regular: 500;
  --font-weight--bold: 700;
  --font-weight--black: 900;


  /* gantt  */
  --gantt-bar-height: 44px;
  --gantt-bar-quantile-height: 42px;


  /* histogram */
  --histogram-bar-group-height: 45px;


  /* menu */
  --menu--green: #085;
  --menu--green-hover: #4e8;


  /* z-index */
  --background-guaranteed: -1000;
  --step-right-back: -2;
  --z-index--step-back: -1;
  --neutral: 0;
  --z-index--step-forward: 1;
  --z-index--step-right-forward: 2;
  --z-index--front-guaranteed: 1000;
  --z-index--menu-guaranteed: 1500;
}



.app-wrapper {
  margin: var(--padding--medium) var(--padding--medium) 200px;
}


.row-layout {
  display: flex;
}
.column-layout {
  display: flex;
  flex-direction: column;
  &.right {
    align-items: flex-end;
  }
}

.space-children > * {
  margin-right: var(--spacing--narrow);
}
.space-children > *:last-child {
  margin-right: 0;
}
.space-children--with-border > * {
  border-right: 1px solid var(--grey--light);
  margin-right: var(--spacing--narrow);
  padding-right: var(--spacing--narrow);
}
.space-children--with-border > *:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: unset;
}

.space-children--wide > * {
  margin-right: var(--spacing--wide);
}
.space-children--wide > *:last-child {
  margin-right: 0;
}
.space-children--wide-with-border > * {
  border-right: 1px solid var(--grey--light);
  margin-right: var(--spacing--wide);
  padding-right: var(--spacing--wide);
}
.space-children--wide-with-border > *:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.space-children--narrowest > * {
  margin-right: var(--spacing--narrowest);
}
.space-children--narrowest > *:last-child {
  margin-right: 0;
}

.space-children--column > * {
  margin-bottom: var(--spacing--narrow);
}
.space-children--column > *:last-child {
  margin-bottom: 0;
}
.space-children--column-with-border > * {
  border-bottom: 1px solid var(--grey--very-light);
  margin-bottom: var(--spacing--narrow);
  padding-bottom: var(--spacing--narrow);
}
.space-children--column-with-border > *:last-child {
  margin-bottom: 0;
}

.space-children--wide-column > * {
  margin-bottom: var(--spacing--wide);
}
.space-children--wide-column > *:last-child {
  margin-bottom: 0;
}
.space-children--wide-column-with-border > * {
  border-bottom: 1px solid var(--grey--very-light);
  margin-bottom: var(--spacing--wide);
  padding-bottom: var(--spacing--wide);
}
.space-children--wide-column-with-border > *:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
