@import '../../util/Constant/sass-variable-list.scss';

.map-svg-control-list {
  @include column-layout();
  align-items: center;
  & > * {
    @include self-space-column-add-border();
  }
  @media (min-width: $media-switch--medium) {
    @include row-layout();
    & > * {
      @include space-row();
      @include self-space-column-remove-border();
    }
  }
  background-color: rgba($white, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: $padding--medium;
  position: relative;
  right: 0;
  width: 100%;
  height: 14vh;

  @media (min-width: $media-switch--medium) {
    height: 4vh;
  }

  &__scroll-key-hint {
    position: relative;
  }

  &__zoom {
    @include column-layout();
    position: relative;
    & > * {
      @include space-column();
    }
    @media (min-width: $media-switch--medium) {
      @include row-layout();
      align-items: center;
      & > * {
        @include space-row();
        @include self-space-column-remove-border();
      }
      height: 4vh;
    }
  }

  &__zoom-label {
    align-self: center;
    text-align: right;
    min-width: 60px;
    &.zoom-focus {
      font-weight: $font-weight--bold;
    }
  }

  &__zoom-buttons {
    @include row-layout();
    align-items: center;
    & > * {
      @include space-row();
    }
    flex-wrap: wrap;
    button {
      @media (max-width: $media-switch--medium) {
        margin-bottom: $spacing--narrow;
      }
    }
  }
}
