@import '../../util/Constant/sass-variable-list.scss';

.histogram-bar-label {
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 0 1px $grey--dark;
  display: none;
  font-size: $font-size--small;
  justify-content: center;
  padding: 0 $padding--tiniest;
  position: absolute;
  top: 4px;

  &:hover {
    z-index: $z-index--front-guaranteed;
  }

  @media (min-width: $media-switch--medium) {
    font-size: $font-size--medium;
  }
}