@import '../../util/Constant/sass-variable-list.scss';

.x-axis-line-list {
  position: relative;
  width: 100%;
  &__line {
    background-color: $grey--light;
    position: absolute;
    height: 2px;
    opacity: 0.5;
    width: 100%;
    z-index: $z-index--step-right-back;
    &--highlight {
      background-color:$blue--dark;
    }
  }
  &__num-list {
    display: flex;
    justify-content: space-between;
  }
}